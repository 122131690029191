.writers {
  &-item {
    &.fixed {
      margin-top: -150px;
      @media(max-width: 767px) {
        margin-top: -50px;
      }
    }

    &__picture {
      width: 72px;
      height: 72px;
      border-radius: $rounded-pill;
      object-fit: cover;
      box-shadow:$box-shadow;

      &--lg {
        width: 110px;
        height: 110px
      }

      &--2x {
        width: 200px;
        height: 200px
      }

      &--3x {
        width: 250px;
        height: 250px;
        @media(max-width: 767px) {
          width: 125px;
          height: 125px;
        }
      }
    }

    &__content {
      font-size: 15px;
      font-weight: 300;
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      display:block;
      line-height: 1;

      &--lg {
        font-size: 30px;
        @media(max-width: 767px) {
          font-size: 20px;
        }
      }
    }

    &__desc {
      font-size: 15px;
      margin-top: 5px;
      font-weight: 300;
      display:block;
    }

    &--post {
      .writers-item {
        &__title {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}
