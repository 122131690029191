.se-social {
  margin: -5px 0;
  font-weight:500;
  font-size:18px;

  &-item {
    margin: 5px 0;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    line-height: 45px;
    color:$secondary;

    i {
      width:45px;
      text-align:center;
    }

    span {
      border-left: 1px solid #D8D8D8;
      padding:0 16px;
    }
  }
}
