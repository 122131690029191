.news-item {
  &__picture {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.50);
    border-radius: 4px;
    overflow: Hidden;

    &-md {
      width: 90px !important;
      height: 90px !important;
    }

    &-lg {
      height: 180px !important;
    }

    &-xl {
      height: 220px !important;
    }

    &-2xl {
      height: 350px !important;
    }

    &-3xl {
      height: 400px !important;
    }

    &-4xl {
      height: 450px !important;
    }
  }

  &__date {
    font-weight: 600;
    font-size: 12px;
    color: #666666;

    span {
      margin-left: 5px;
    }
  }

  &__tags {
    .badge {
      border-radius: 2px;
      font-size: 12px;
      font-weight: 500;
      color: White;
      background-color: #FF0000;
      padding: 4px 6px;
    }
  }

  &__writer {
    font-weight: 300;
    font-size: 12px;
    display: block;
    color: $gray-600;
    overflow: hidden;

    a {
      color: $gray-600;
    }

    &-lg {
      font-size: 15px;
    }
  }

  &__title {
    font-size: 17px;
    display: block;
    font-weight: 500;
    line-height: 1.25;

    &-sm {
      font-size: 15px;
    }

    &-lg {
      font-size: 20px;
    }
  }

  &__desc {
    color: rgba(0, 0, 0, 0.80);
    margin-top: 8px;
    font-weight: 300;
  }

  &--tiny {
    .news-box {
      height: 84px;
      @media(max-width: 767px) {
        height: auto;
      }

      &::before {
        right: 0;
      }
    }

    .news-item {
      &__title {
        font-size: 14px;
      }
    }
  }

  &-sm {
    &.locked {
      height: calc(426px / 3 - 20px);

      @media(max-width: 767px) {
        height: auto;
      }
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @media(max-width: 767px) {
        margin-bottom: 1rem;
      }
    }

    .news-box {

      @media(max-width: 767px) {
        height: auto;
      }

      &::before {
        right: 0;
      }
    }

    .news-item {
      &__title {
        font-size: 15px;
      }

      &__writer {
        font-size: 12px;
      }
    }
  }

  &--small {
    .news-box {
      height: 140px;
      @media(max-width: 767px) {
        height: 200px;
      }

      &::before {
        right: 0;
      }
    }

    .news-item {
      &__title {
        font-size: 15px;
      }

      &__writer {
        font-size: 12px;
      }
    }
  }
}
