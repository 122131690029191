.footer {
  color: white;

  a {
    color: white;
  }

  &__top {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }
}
