.news-box {
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  $self: &;

  &.locked {
    height: 225px;
  }

  .news-item {
    &__writer {
      a {
        color: white;
      }

      color: white;
    }

    &__picture {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      color: white;

      &:hover {
        color: White;
      }
    }
  }

  &-lg {
    height: 426px;
  }

  // #{ $self }-title & {
  //   color: $gray-600;
  //   font-size: 14px;
  //   font-weight: 400;
  // }
  &:Hover {
    color: white;
  }

  @media(max-width: 767px) {
    height: auto;
  }

  &__title {
    color: white;

    &:hover {
      color: White;
    }
  }

  &__picture {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &:not(&--no-shadow) {
    .news-box__picture {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        transition: all 0.5s;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, .1) 25%, #000 100%);
      }
    }

    &:hover {
      .news-box__picture {
        &::after {
        }
      }
    }
  }


  &--video, &--picture {
    &::before {
      background: $primary;
      position: absolute;
      top: 0;
      right: 12px;
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
    }
  }

  &--video {
    &::before {
      content: "\f03d";
    }
  }

  &--picture {
    &::before {
      content: "\f030";
    }
  }

  &__content {
    width: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
  }

  &__writer {
    font-weight: 600;
    display: flex;
    font-size: 12px;

    img {
      height: 30px;
      width: 30px;
    }

    span {
      margin-left: 8px
    }
  }

  &__date {
    font-weight: 600;
    font-size: 12px;

    span {
      margin-left: 8px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
  }

  &__content {
    padding: 16px;

    @media(min-width: 576px) {
      padding: 24px;
    }
  }
}

.news-box-lg {
  min-height: 200px;

  .news-box {
    &__content {

      @media(min-width: 768px) {
        padding: 2rem;
      }
    }
  }

  .news-item {
    &__title {
      font-weight: 900;
      line-height: 1.1;

      @media(min-width: 576px) {
        font-size: 20px;
      }

      @media(min-width: 768px) {
        font-size: 30px;
      }
    }

    &__writer {
      font-size: 15px;
      @media(min-width: 576px) {
        font-size: 18px;
      }
    }
  }
}
