.social-list {
  margin: -5px 0;
  display: flex;
  flex-direction: column;

  &-item {
    display: flex;
    align-items: center;
    height: 42px;
    color: white;
    margin: 5px 0;
    color: white;

    &:hover {
      color: white;
    }

    &__icon {
      font-size: 18px;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      width: 42px;
      border-right: 2px solid rgba(#000, 0.2);
    }

    &__title {
      padding: 0 16px;
      font-weight: 500;
      font-size: 18px
    }
  }
}
