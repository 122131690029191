.page-head {
  position: relative;
  height: 350px;
  display: flex;
  align-items: center;
  color: White;
  margin-top: -2rem;
  margin-bottom: 2rem;
  background-size: cover;
  background-position: center;
  padding: 50px 0;

  @media(max-width: 575px) {
    height: auto;
    padding: 24px 0;
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: 42px;
    line-height: 1.1;
    font-weight: 900;

    @media(max-width: 767px) {
      font-size: 24px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    transition: all 0.5s;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .1) 25%, #000 100%);
  }
}


.slider-home {
  position: relative;

  &-items {
    .swiper-slide {
      @media(min-width: 576px) {
        height: 500px;
      }
      @media(max-width: 575px) {
        padding-top: 32px;
        padding-bottom: 32px;
      }
    }

    //.news-box {
    //  $self: &;
    //
    //  #{ $self }-title & {
    //    color: $gray-600;
    //    font-size: 14px;
    //    font-weight: 400;
    //  }
    //}

    .news-box {
      display: flex;
      position: relative;
      justify-content: center;

      @media(min-width: 576px) {
        justify-content: flex-end;
        padding-bottom: calc(80px + 64px + 32px);
      }

      @media(max-width: 575px) {
        padding-left: 32px;
        padding-right: 32px;
      }

      &__picture {
        position: Absolute;
        left: 0;
        top: 0;
      }

      &__content {
        position: relative;
      }

      &__title {
        font-size: 45px;
        line-height: 1.1;
        font-weight: 900;

        @media(max-width: 767px) {
          font-size: 24px;
        }

        @media(max-width: 575px) {
          font-size: 20px;
        }
      }
    }
  }

  &-thumbs {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 64px;

    &-item {
      height: 92px;

      &__desc {
        height: 80px;
        line-height: 20px;
        overflow: Hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    .swiper-slide {
      padding: 5px;
      overflow: hidden;
      color: White;
      line-height: 20px;
      font-weight: 500;
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: $border-radius;

      &-thumb-active {
        border-color: rgba(255, 255, 255, 0.2);
      }

      img {
        height: 80px;
        border-radius: $border-radius;
        width: 80px;
        object-fit: cover;
      }
    }
  }
}
