.news-slider {
  margin-top: -10px;

  &-item {
    margin-top: 10px;
    height: 175px;
    color: white;

    &:hover {
      color: white
    }

    .news-box__title{
      color:white;
    }

    @media(max-width: 767px) {
      height: auto;
    }

    @media(max-width: 767px) {
      height: auto;
      .news-slider {
        &-item {
          &__picture {
            height: auto;
          }
        }
      }
    }

    &__picture {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--big {
      @media(min-width: 768px) {
        height: 360px;
        &.news-box {
          .news-box {
            &__title {
              font-size: 22px;
              line-height: 1.25;
            }

            &__content {
              padding: 16px 24px;
            }

            &__date {
              font-size: 13px;
            }

            &__writer {
              font-size: 13px;
            }
          }
        }

      }
    }

  }
}
