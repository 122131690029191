@import "./header";
@import "./section";
@import "./news-item";
@import "./news-box";
@import "./news-slider";
@import "./list-border";
@import "./social-list";
@import "./writers";
@import "./playlist";
@import "./websites";
@import "./slider-home";
@import "./se-social";
@import "./footer";

.slide-button-next, .slide-button-prev {
  box-shadow: none !important;
}

.lazy-container {
  &:before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: 50%;
    margin-top: -7.5px;
    left: 50%;
    margin-left: -7.5px;
    border-radius: 50%;
    border: 2px solid #3c3b3b;
    -webkit-animation: lazy-load-animation 1s ease-out infinite;
    animation: lazy-load-animation 1s ease-out infinite;
    -ms-animation: lazy-load-animation 1s ease-out infinite;
    -moz-animation: lazy-load-animation 1s ease-out infinite;
    -o-animation: lazy-load-animation 1s ease-out infinite;
  }
}

#backToUp {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 55;

  &:not(.show) {
    display: none;
  }
}

.headerNews {
  padding-bottom: 16px;
  width: 100%;

  .section {
    &__toolbar {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
