
.section {
  &__head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 12px;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    position: relative;
    color: $gray-600;
    @media(max-width: 550px) {
      font-size: 24px;
    }

    i {
      font-size: 20px;
      margin-left: 12px;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
