*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  outline: 0;
}

.h-full {
  height: 100%;
}

@import "utils/utils";
@import "components/components";


.swiper-container {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled, .swiper-button-disabled {
  opacity: 0.5
}

.wrapper {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

h6 {
  font-weight: bold;
  font-size: 15px;
}

.body-text a {
  color: #265977;
}

.body-text {
  font-size: 16px;
  color: #000;
}

.img-box {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 39%, #000000 100%);
  }

  img {
    width: 100%;
  }
}

img {
  display: block;
  max-width: 100%;
}

body {
  &.dropdown-menu-active {
    &::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 54;
      background-color: rgba($gray-600, 0.9);
    }
  }
}

.big {
  font-size: 18px;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.lazy {
  background-image: url(../images/loading.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
}


#sliderGallery {
  position: relative;
  overflow: hidden;

  .swiper-slide {
    height: 400px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    background-position: center;
    justify-content: end;
    @media(max-width: 575px) {
      height: 250px;
    }

    .video-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      overflow: hidden;

      iframe, .youtube-background {
        width: 100% !important;
        height: 56.25vw !important;; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100% !important;;
        min-width: 100% !important;; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50% !important;;
        left: 50% !important;;
        transform: translate(-50%, -50%) !important;;
      }
    }

    video {
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }
}

.cookie {
  background: $primary;
  color: white;
  z-index: 54;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;

  a {
    color: white;
  }
}

.page__title {
  font-weight: 600;
  margin-bottom: 20px
}

.p-maintenance {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.new-picture-slider {
  .swiper-slide {
    position: relative;

    img {
      width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 39%, #000000 100%);
    }
  }

  &--big {
    .swiper-slide {
      height: 600px;
      @media(max-width: 991px) {
        height: 400px;
      }
      @media(max-width: 767px) {
        height: 350px;
      }
      @media(max-width: 550px) {
        height: 300px;
      }
    }
  }

  .swiper {
    &-button {
      &-next, &-prev {
        width: 30px;
        height: 38px;
        background-size: 13px auto;
        background-color: rgba(0, 0, 0, 0.30);
      }

      &-prev {
        left: 0;
      }

      &-next {
        right: 0;
      }
    }
  }
}

.bg-facebook {
  background-color: #3B5998;
}

.bg-twitter {
  background-color: #00ACED;
}

.bg-instagram {
  background-color: #C32AA3;
}

.bg-pinterest {
  background-color: #e6011a;
}

.bg-whatsapp {
  background-color: #31bd30;
}

.bg-linkedin {
  background-color: #017fb2;
}

.bg-youtube {
  background-color: #FF0000;
}

.bg-gray {
  background-color: #707070;
}

.bg-language {
  background-color: #707070;
}

#share{
  margin:-1px;
}

.btn {
  &-social {
    color: White;
    margin:1px;
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    padding: 0;
    display: inline-block;
    font-size:18px;

    &:hover {
      color: White;
      opacity: 0.8;
    }
  }
}

body {
  &.navbar-opened {
    overflow: hidden;

    &::after {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.75);
      content: "";
    }
  }
}


a {
  transition: border 0.3s, color 0.3s, background-color 0.3s;
}
