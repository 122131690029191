.video-playlist {
  position: relative;

  &-controls {
    height: 65px;
    background-color: $primary;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;

    &:before {
      content: '';
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAGBAMAAADwPukCAAAAElBMVEUAAAAAAAAAAAAAAAAAAAAAAADgKxmiAAAABnRSTlM9KRgMBADiSB2HAAAAFElEQVR4XmNgYBBgUGAwYHBgCAAAA3wA8fpXm6EAAAAASUVORK5CYII=) repeat-x;
      width: 100%;
      height: 6px;
      position: absolute;
      bottom: -6px;
      z-index: 1;
    }

    &__stop {
      position: relative;
      outline: 0 !important;

      &:after {
        content: '';
        width: 1px;
        height: 36px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: calc(50% - 18px);
        left: 80px;
        right: 0;
      }

      .youtube_control, .vimeo_control {
        width: 80px;
        height: 65px;
        position: relative;
        cursor: pointer;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "\f04b";
          display: block;
          color: white;
          align-items: center;
          justify-content: center;
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
        }

        &.video-pause {
          &::after {
            content: "\f04c"
          }
        }
      }
    }

    .video-playlist-item__title_playing {
      flex: 1;
      padding-left: 10px;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: #ffffff;
      max-height: 36px;
      overflow: hidden;
    }

    .video-playlist-item__time_playing {
      position: absolute;
      bottom: 0px;
      right: 2px;
      font-size: 10px;
      font-style: italic;
      color: #ffffff;
      line-height: 17px;
    }
  }

  .video_currently_playing {
    background-color: rgba(255, 255, 255, 0.1);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-left: 3px solid #4db2ec !important;
      width: 3px;
      height: inherit;
    }
  }

  .video-playlist-item {
    padding: 10px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }

    &__picture {
      position: relative;
      width: 80px;
      height: 45px;
      overflow: hidden;
      margin-right: 10px;

      img {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__detail {
      flex: 1;
    }

    &__title {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 18px;
      max-height: 36px;
      overflow: hidden;
    }

    &__time {
      font-size: 10px;
      font-style: italic;
      color: rgba(255, 255, 255, 0.5);
      line-height: 13px;
      margin-top: 4px;
    }
  }

  .video-playlist__player {
    background-color: #000;
    height: 200px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .video-playlist__content {
    background-color: $secondary;
    vertical-align: top;
    height: 410px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .playlist_clickable {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    flex: 1;
  }
}

.video_playlist_column_3 {
  .video-playlist {
    left: -1px;
    z-index: 1;

    /* responsive portrait phone */
  }

  .video_playlist_title {
    left: -1px;
    width: 1021px;

    /* responsive portrait tablet */
    /* responsive portrait phone */
  }

  .video-playlist__player {
    display: block;
    width: 681px;
    height: 413px;
  }

  .video-playlist__content {
    position: absolute;
    top: 0px;
    right: -2px;
    width: 340px;
    height: 413px;
  }

  .playlist_clickable {
    &.add_scrollbar_to_playlist_for_mobile {
      height: 348px;
    }

    &.add_scrollbar_to_playlist {
      height: 348px;

      &::-webkit-scrollbar-track {
        background-color: #383838;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #919191;
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .video_playlist_column_3 .video-playlist {
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .video_playlist_title {
    width: 756px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_3 .video_playlist_title {
    width: 100%;
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .video-playlist__player {
    display: block;
    width: 756px;
  }
}

@media (min-width: 361px) and (max-width: 767px) {
  .video_playlist_column_3 .video-playlist__player {
    display: block;
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 360px) {
  .video_playlist_column_3 .video-playlist__player {
    display: block;
    width: 100%;
    height: 192px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 20px;
    top: 23px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .video_playlist_column_3 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 20px;
    top: 23px;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_3 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 37px;
    top: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .video-playlist__content {
    display: block;
    position: relative;
    width: 756px;
    height: 305px;
    right: 0px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_3 .video-playlist__content {
    display: block;
    position: relative;
    width: 100%;
    height: 305px;
    right: 0px;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_3 .video-playlist__content {
    position: relative;
    height: 245px;
    right: 0px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist_for_mobile {
    margin-right: 10px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 767px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist_for_mobile {
    margin-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist_for_mobile {
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #383838;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #919191;
      border-radius: 10px;
    }
  }
}

@media (max-width: 767px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist_for_mobile {
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: #383838;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #919191;
      border-radius: 10px;
    }
  }
}

@media (max-width: 480px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist_for_mobile {
    height: 180px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist {
    margin-right: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist {
    height: 240px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist {
    height: 240px;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_3 .playlist_clickable.add_scrollbar_to_playlist {
    height: 180px;
  }
}

.video_playlist_column_2 {
  .video-playlist {
    left: -1px;
    z-index: 1;

    /* responsive portrait phone */
  }

  .video_playlist_title {
    left: -1px;
    width: 681px;

    /* responsive portrait tablet */
    /* responsive portrait phone */
  }

  .video-playlist__player {
    display: block;
    width: 681px;
    height: 412px;
  }

  .video-playlist__content {
    display: block;
    width: 681px;
    height: 305px;

    .playlist_clickable {
      height: 240px;

      &.add_scrollbar_to_playlist_for_mobile::-webkit-scrollbar-track, &.add_scrollbar_to_playlist::-webkit-scrollbar-track {
        background-color: #383838;
      }

      &.add_scrollbar_to_playlist_for_mobile::-webkit-scrollbar, &.add_scrollbar_to_playlist::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }

      &.add_scrollbar_to_playlist_for_mobile::-webkit-scrollbar-thumb, &.add_scrollbar_to_playlist::-webkit-scrollbar-thumb {
        background-color: #919191;
        border-radius: 10px;
      }
    }
  }

  .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 20px;
    top: 23px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_2 .video-playlist {
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_2 .video_playlist_title {
    width: 505px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_2 .video_playlist_title {
    width: 100%;
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_2 .video-playlist__player {
    width: 505px;
    height: 283px;
  }
}

@media (min-width: 361px) and (max-width: 767px) {
  .video_playlist_column_2 .video-playlist__player {
    display: block;
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 360px) {
  .video_playlist_column_2 .video-playlist__player {
    display: block;
    width: 100%;
    height: 192px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_2 .video-playlist__content {
    width: 505px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_2 .video-playlist__content {
    display: block;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_2 .video-playlist__content {
    height: 245px;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_2 .video-playlist__content .playlist_clickable {
    height: 180px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .video_playlist_column_2 .video-playlist__content .playlist_clickable {
    &.add_scrollbar_to_playlist_for_mobile, &.add_scrollbar_to_playlist {
      margin-right: 10px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_2 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 20px;
    top: 23px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .video_playlist_column_2 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 20px;
    top: 23px;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_2 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 37px;
    top: 13px;
  }
}

.video_playlist_column_1 {
  .video-playlist {
    left: -1px;
    z-index: 1;

    /* responsive portrait phone */
  }

  .video_playlist_title {
    left: -1px;
    width: 341px;

    /* responsive portrait tablet */
    /* responsive portrait phone */
  }

  .video-playlist__player {
    display: block;
    width: 341px;
    height: 190px;
  }

  .video-playlist__content {
    display: block;
    width: 341px;
    height: 305px;

    .playlist_clickable {
      &.add_scrollbar_to_playlist_for_mobile, &.add_scrollbar_to_playlist {
        height: 240px;
      }

      &.add_scrollbar_to_playlist_for_mobile::-webkit-scrollbar-track, &.add_scrollbar_to_playlist::-webkit-scrollbar-track {
        background-color: #383838;
      }

      &.add_scrollbar_to_playlist_for_mobile::-webkit-scrollbar, &.add_scrollbar_to_playlist::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }

      &.add_scrollbar_to_playlist_for_mobile::-webkit-scrollbar-thumb, &.add_scrollbar_to_playlist::-webkit-scrollbar-thumb {
        background-color: #919191;
        border-radius: 10px;
      }
    }
  }

  .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 37px;
    top: 13px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_1 .video-playlist {
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_1 .video_playlist_title {
    width: 252px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_1 .video_playlist_title {
    width: 100%;
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_1 .video-playlist__player {
    width: 252px;
    height: 143px;
  }
}

@media (min-width: 361px) and (max-width: 767px) {
  .video_playlist_column_1 .video-playlist__player {
    display: block;
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 360px) {
  .video_playlist_column_1 .video-playlist__player {
    display: block;
    width: 100%;
    height: 192px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .video_playlist_column_1 .video-playlist__content {
    width: 252px;
  }
}

@media (max-width: 767px) {
  .video_playlist_column_1 .video-playlist__content {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .video_playlist_column_1 .video-playlist__content {
    height: 245px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .video_playlist_column_1 .video-playlist__content .playlist_clickable {
    &.add_scrollbar_to_playlist_for_mobile, &.add_scrollbar_to_playlist {
      margin-right: 10px;
    }
  }
}

@media (max-width: 480px) {
  .video_playlist_column_1 .video-playlist__content .playlist_clickable {
    &.add_scrollbar_to_playlist_for_mobile, &.add_scrollbar_to_playlist {
      height: 180px;
    }
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .video_playlist_column_1 .video-playlist-controls .video-playlist-item__title_playing {
    max-height: 20px;
    top: 23px;
  }
}

.video-playlist {
  &--vertical {
    display: flex;
    flex-wrap: wrap;

    .video-playlist {
      &-controls {
        &__stop {
          &:after {
            left: 60px;
          }

          .youtube_control, .vimeo_control {
            width: 60px;
          }
        }
      }

      &__player {
        flex: 1;
        height: 270px;
        width: 100%;
        @media(max-width: 575px) {
          flex: auto;
        }

        @media(max-width: 767px) {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      &__content {
        width: 250px;
        height: 270px;
        @media(max-width: 575px) {
          width: 100%;
        }
      }

      &-item {
        padding-top: 8px;
        padding-bottom: 8px;

        &__picture {
          width: 60px;
          height: 30px;
        }

        &__title {
          line-height: 14px;
          font-size: 12px;
          max-height: 28px;
        }

        &__time {
          margin-top: 2px;
        }
      }
    }
  }
}
