.websites {
  .swiper-slide {
    text-align: center;
  }

  @media(min-width:1024px){
    .swiper-wrapper{
    }
  }

  &-item {
    width: 100%;
    text-align: center;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }
}
