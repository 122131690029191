@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@media (min-width: 992px) {
  .dropdown-menu {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

.dropdown-menu {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.font-weight-medium {
  font-weight: 500
}

.font-weight-semibold {
  font-weight: 600
}

.navbar-brand img {
  max-height: 62px;
}

body.navbar-opened {
  .header__navbar {
    left: 0;
  }
}

.topbar {
  background: $primary;
  color: unset;
  font-size: 12px;

  a {
    color: unset;
  }

  &-menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    a {
      position: relative;
      padding: 8px 20px;
      font-weight: 300;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:not(:last-child)::after {
        content: "";
        width: 1px;
        height: calc(100% - 24px);
        background-color: white;
        display: block;
        position: absolute;
        right: 0;
        top: 12px;
      }
    }
  }

  &-social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    a {
      padding: 8px;
    }
  }
}

.header {
  background: #fff;
  z-index: 55;
  position: relative;
  @media(min-width: 992px) {
    padding-top: 45px;
  }

  &__topbar {
    background: $primary;
    color: white;
    font-size: 12px;

    a {
      color: white;
    }
  }

  &__middle {
    padding: 32px 0;
    border-bottom: 1px solid #eee;
    @media(max-width: 991px) {
      padding: 16px 0;
      z-index: 5;
      position: relative;
    }
  }

  &__navbar {
    background: $light;
    box-shadow: 0 4px 6px 0 rgba(168, 168, 168, 0.50);
    z-index: 5 !important;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    @media(max-width: 991px) {
      top: 103px;
      border-top: 1px solid #eee;
      left: -100%;
      transition: all 0.15s;
      width: 100% !important;
      position: absolute;
      height: calc(100vh - 103px) !important;
      overflow: auto;
      background: #f8f9fa;
      .dropdown-menu {
        border-bottom: 0;
      }
    }

    a {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .search-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-600, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    z-index: 55;

    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 4;
    }

    &:not(.active) {
      display: None;
    }

    form {
      z-index: 5;
      position: relative;
      max-width: 960px;
      width: 100%;

      input {
        flex: 1;
        border: 0;

        @media(max-width: 575px) {
          padding-right: 32px;
          font-size: 14px;
          height: 48px;
        }
      }
    }

    &__close {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
    }
  }
}

.navbar-nav .dropdown-menu {
  position: relative;
}


.navbar-classic {
  padding: 0;

  .navbar-collapse {
    width: 100%;
  }

  @media(max-width: 991px) {
    padding-left: 0;
    padding-right: 0;

    > .container {
      padding: 0;
    }
    .nav-link {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .nav-item {
    &:hover {
      .nav-link {
        background: $primary;
        color: white;
      }
    }
  }

  .dropdown-toggle::after {
    margin-top: 1px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    vertical-align: -2px;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    color: #bdbebf;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    margin-right: 15px;
    font-size: 13px;
    float: right;
    content: "\f078";
  }

  .navbar-collapse {
    @media(max-width: 991px) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .navbar-collapse.show .dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f1f1f6;
    border-radius: 0px;
  }
}

.dropdown-menu {
  position: absolute;
  margin-top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 16rem;
  padding: 12px;
  font-size: 1rem;
  color: #808294;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0;

  a {
    color: $dark;
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 12px 16px;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 15px;
  line-height: 15px;

  &:focus, &:hover {
    text-decoration: none;
    border-radius: 4px;
  }

  &:focus .dropdown-toggle::after, &:hover.dropdown-toggle::after {
  }
}

.dropdown-submenu {
  position: relative;

  a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 17px;
    top: 25px;
  }

  .dropdown-menu {
    top: 0px;
    left: 100%;
    margin-left: 13px;
    margin-right: .1rem;
    @media(max-width: 991px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

}

.text-md {
  font-size: 15px;
}

.text-sm {
  font-size: 14px;
}

.mega-dropdown {
  position: unset;
}

.mega-dropdown-menu {
  width: 100%;
  position: absolute;
  padding: 24px;
  background: $primary;

  ul {
    list-style: none;
  }
}

.icon-bar {
  width: 21px;
  height: 3px;
  background-color: $secondary;
  display: block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin-top: 4px
}

.navbar-toggler {
  &:focus {
    outline: 0px;
  }

  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 190%;
    background-color: $secondary;
  }

  .middle-bar {
    opacity: 0;
  }

  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% -80%;
    background-color: $secondary;
  }

  &.collapsed {
    .top-bar {
      transform: rotate(0);
      background-color: $secondary;
    }

    .middle-bar {
      opacity: 1;
    }

    .bottom-bar {
      transform: rotate(0);
      background-color: $secondary;
    }
  }
}

.navbar-brand {
  @media(max-width: 991px) {
    img {
      max-height: 55px;
    }
  }
}
