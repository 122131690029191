@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src:
      url(#{$path}.woff) format("woff"),
      url(#{$path}.woff2) format("woff2"),
      url(#{$path}.ttf) format("truetype");
    font-weight: $weight;
    font-style: $style;
  }
}
// @include font-face('Open Sans', '../fonts/opensans/opensans/opensans', 400, 'normal');
// @include font-face('Open Sans', '../fonts/opensans/opensanslight/opensanslight', 300, 'normal');
// @include font-face('Open Sans', '../fonts/opensans/opensanssemibold/opensanssemibold', 600, 'normal');

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,600,700,800&display=swap&subset=latin-ext');
